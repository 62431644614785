var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "component-container" }, [
    _c("h1", { staticClass: "display-6 mt-4 mb-3" }, [
      _vm._v("Report Builder"),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "col-sm-6" }, [
      _vm._v(
        "Use this form to request a new report. The report will be saved to the Reports page folder structure."
      ),
    ]),
    _vm._v(" "),
    _c("form", { staticClass: "needs-validation", attrs: { novalidate: "" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 mb-3" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "company" } },
            [_vm._v("Company")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.company,
                  expression: "company",
                },
              ],
              staticClass: "form-select",
              attrs: { id: "company", required: "" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.company = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.checkRequestButtonAvailable()
                  },
                ],
              },
            },
            [
              _c(
                "option",
                { attrs: { selected: "", disabled: "", value: "0" } },
                [_vm._v("Please Select a Company")]
              ),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("CINC")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("Real Geeks")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 mb-3" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "reportType" } },
            [_vm._v("Report Type")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reportType,
                  expression: "reportType",
                },
              ],
              staticClass: "form-select",
              attrs: { id: "reportType", required: "" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.reportType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.checkRequestButtonAvailable()
                  },
                ],
              },
            },
            [
              _c(
                "option",
                { attrs: { selected: "", disabled: "", value: "-1" } },
                [_vm._v("Please Select a Report Type")]
              ),
              _vm._v(" "),
              _c("option", { attrs: { value: "0" } }, [
                _vm._v("Current Month Lead Report"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [
                _vm._v("Previous Month Lead Report"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [
                _vm._v("Custom Date Range Lead Report"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [
                _vm._v("Last Thirty Lead Report"),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.reportType == 2
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-3 mb-3" }, [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "startDate" } },
                [_vm._v("Start Date")]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "date", id: "startDate", required: "" },
                domProps: { value: _vm.getStartDateString() },
                on: { change: (event) => _vm.setStartDate(event) },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-3 mb-3" }, [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "endDate" } },
                [_vm._v("End Date")]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "date", id: "startDate", required: "" },
                domProps: { value: _vm.getEndDateString() },
                on: { change: (event) => _vm.setEndDate(event) },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 mt-2 mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: _vm.reportButtonDisabled },
              on: { click: _vm.sendReportRequest },
            },
            [_vm._v("Request Report")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.displayAccepted
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 mb-3" }, [
              _c(
                "div",
                {
                  staticClass: "alert alert-success",
                  attrs: { role: "alert" },
                },
                [
                  _vm._v(
                    "\n                    Report Request Accepted. Please check the Facebook Automation slack channel for notification of the report completion. Then download from the "
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/facebook/reports/existing" } },
                    [_vm._v("Reports")]
                  ),
                  _vm._v(" page.\n                "),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.displayError
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 mb-3" }, [
              _c(
                "div",
                { staticClass: "alert alert-danger", attrs: { role: "alert" } },
                [
                  _vm._v(
                    "\n                    Error: " +
                      _vm._s(_vm.errorMessage) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }