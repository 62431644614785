<template>
    <div class="d-flex">
        <Sidebar/>
        <div class="component-container-sidebar">
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Ad Account Creator
                </div>
                <div class="card-body">
                    <form v-if=!displaySuccess class="needs-validation" novalidate :class="formValidationClass">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label for="company" class="form-label">Company</label>
                                <select v-model="company" class="form-select" id="company" required>
                                    <option selected disabled value=0>Please Select a Company</option>
                                    <option value=1>CINC</option>
                                    <option value=2>Real Geeks</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="isCompanySelected()">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <label for="clientFullName" class="form-label">Client Full Name</label>
                                    <input v-model="clientFullName" type="text" class="form-control" id="clientFullName" placeholder="Client Full Name" required>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="domainName" class="form-label">Domain Name</label>
                                    <input v-model="domainName" type="text" class="form-control" id="domainName" placeholder="cincpro" required @input="validateInput">
                                </div>

                                <div v-if=displayCincElements() class="col-md-4 mb-3">
                                    <label for="brokerage" class="form-label">Brokerage</label>
                                    <select v-model="brokerage" class="form-select" id="brokerage" required>
                                        <option selected disabled value="">Please Select a Brokerage</option>
                                        <option v-for="brokerage in brokerages" v-bind:key="brokerage" :value="brokerage">
                                            {{ brokerage }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if=!displayCincElements() class="col-md-4 mb-3">
                                    <label for="brokerage" class="form-label">Time Zone</label>
                                    <select v-model="timeZone" class="form-select" id="timeZone" required>
                                        <option selected disabled value="">Please Select a Time Zone</option>
                                        <option v-for="timeZone in timeZones" :key="timeZone" :value="timeZone">
                                            {{ timeZone }}
                                        </option>
                                    </select>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label for="dynamicAudienceId" class="mb-2">States</label>
                                    <div class="card" :class="locationsClass">
                                        <div class="card-body">
                                            <div class="row">
                                                <div v-for="location in locations" v-bind:key="location[0]" class="col-md-4 mb-1">
                                                    <div class="btn-group w-100" data-bs-toggle="buttons">
                                                        <label class="btn btn-outline-secondary w-100" :class="isLocationActive(location[0])" @click="locationClick(location[0])">
                                                            {{ location[0] }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div class="form-row">
                                <button type="button" class="btn btn-primary" aria-disabled="true" :disabled=createDisabled @click="createAdAccountRequest" :style="{ 'cursor': createDisabled ? 'wait' : 'pointer' }">
                                    <span v-if="createDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Create Ad Account
                                </button>
                            </div>
                        </div>
                    </form>
                    <div v-if=displaySuccess>
                        <p class="display-1 text-success text-center"><i class="fa fa-check-circle-o"></i></p>
                        <h3 class="text-center text-success mb-4">Success!</h3>
                        <p class="text-center mb-2">The ad account has been created and configured!</p>
                        <p class="text-center mb-2">Ad Account ID: {{ $store.state.creator.creatorInfo.AdAccountId.substring(4) }}</p>
                        <p class="text-center mb-4">Ad Account Name: {{ $store.state.creator.creatorInfo.AdAccountName }}</p>
                        <p class="text-center"><router-link class="btn btn-primary" to='/facebook/creator/campaign'>Continue to Campaign Creation</router-link></p>
                        <p class="text-center"><a class="btn btn-secondary" href='/facebook/creator/adaccount'>Create another Ad Account</a></p>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" role="alert" v-if=displayInfo>
                {{ infoMessage }}
            </div>
            <div class="alert alert-danger" role="alert" v-if=displayError>
                {{ errorMessge }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Company } from '../../enums/Company';
import { AdAccountCreationRequest } from '../../models/request/AdAccountCreationRequest';
import { AdAccountPermissionsRequest } from '../../models/request/AdAccountPermissionsRequest';
import { CreatorInfo} from '../../models/CreatorInfo';
import Sidebar from './Sidebar.vue';

@Component({
    name: 'AdAccountCreator',
    components: {
        Sidebar
    }
})
export default class AdAccountCreator extends Vue{

    mounted() {
        this.form = document.getElementsByClassName('needs-validation')[0];
    }

    locations: [string, string][] =[ 
            [ "Alabama", "AL"], [ "Alaska", "AK" ], [ "Arizona", "AZ" ], [ "Arkansas", "AR" ], [ "California", "CA" ], [ "Colorado", "CO" ], [ "Connecticut", "CT" ], 
            [ "Delaware", "DE" ], [ "District of Columbia", "DC" ], [ "Florida", "FL" ], [ "Georgia", "GA" ], [ "Hawaii", "HI" ], [ "Idaho", "ID" ],
            [ "Illinois", "IL" ], [ "Indiana", "IN" ], [ "Iowa", "IA" ], [ "Kansas", "KS" ], [ "Kentucky", "KY" ], [ "Louisiana", "LA" ], 
            [ "Maine", "ME" ], [ "Maryland", "MD" ], [ "Massachusetts", "MA" ], ["Mexico", "MX"], [ "Michigan", "MI" ], [ "Minnesota", "MN" ], [ "Mississippi", "MS" ], 
            [ "Missouri", "MO" ], [ "Montana", "MT" ], [ "Nebraska", "NE" ], [ "Nevada", "NV" ], [ "New Hampshire", "NH" ], [ "New Jersey", "NJ" ], 
            [ "New Mexico", "NM" ], [ "New York", "NY" ], [ "North Carolina", "NC" ], [ "North Dakota", "ND" ], [ "Ohio", "OH" ], [ "Oklahoma", "OK" ], 
            [ "Oregon", "OR" ], [ "Pennsylvania", "PA" ], [ "Rhode Island", "RI" ], [ "South Carolina", "SC" ], [ "South Dakota", "SD" ], 
            [ "Tennessee", "TN" ], [ "Texas", "TX" ], [ "Utah", "UT" ], [ "Vermont", "VT" ], [ "Virginia", "VA" ], [ "Washington", "WA" ], 
            [ "West Virginia", "WV" ], [ "Wisconsin", "WI" ], [ "Wyoming", "WY" ],
            [ "Alberta", "AB" ], [ "British Columbia", "BC" ], [ "Guam", "GU"], [ "Manitoba", "MB" ], [ "New Brunswick", "NB" ], [ "Newfoundland and Labrador", "NL" ],
            [ "Northwest Territories", "NT" ], [ "Nova Scotia", "NS" ], [ "Nunavut", "NU" ], [ "Ontario", "ON" ], [ "Prince Edward Island", "PE" ],
            [ "Quebec", "QC" ], [ "Saskatchewan", "SK" ], [ "Yukon", "YT" ]
    ];

    brokerages: string[] = [
        "Berkshire Hathaway",
        "Better Homes and Gardens",
        "Century 21", 
        "Coldwell Banker", 
        "Compass", 
        "EXIT",
        "EXP", 
        "Independent", 
        "Keller Williams", 
        "Realty One Group", 
        "ReMax", 
        "Royal LePage", 
        "Sotheby's"
    ];

    timeZones: string[] =[
        "Eastern",
        "Central",
        "Mountain",
        "Pacific",
        "Alaska",
        "Hawaii-Aleutian"
    ]

    company: Company = Company.None;
    clientFullName: string = "";
    domainName: string = "";
    brokerage: string = "";
    timeZone: string = "";
    infoMessage: string = "";
    selectedStates: string[] = [];
    locationsClass = "";
    formValidationClass = "";
    
    createDisabled: boolean = false;
    displayError: boolean = false;
    displaySuccess: boolean = false;
    displayInfo: boolean = false;
    errorMessge: string = "";
    form: any;

    isCompanySelected() : boolean {
        return this.company != Company.None;
    }

    displayCincElements() : boolean {
        return (this.company === Company.CINC);
    }

    validateInput() {
        this.domainName = this.domainName.toLowerCase().replace(/\s/g, '');
    }

    locationClick(id: string) {
        if(this.selectedStates.includes(id))
            this.selectedStates = this.selectedStates.filter(state => state !== id);
        else
            this.selectedStates.push(id);
    }

    isLocationActive(id: string) {
        return this.selectedStates.includes(id) ? 'active' : '';
    }

    async createAdAccountRequest(e: Event) {
        if(this.form) {
            this.formValidationClass = "";

            if(this.selectedStates.length === 0) {
                this.locationsClass = "border-danger";
                this.form.checkValidity();
                this.formValidationClass = "was-validated";
                return;
            }
            else{
                this.locationsClass = "";
            }

            this.displayError = false;
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { //if valid form
                let adAccountCreationRequest = new AdAccountCreationRequest();
                adAccountCreationRequest.ClientFullName = this.clientFullName;
                adAccountCreationRequest.DomainName = this.domainName;
                adAccountCreationRequest.States = this.selectedStates;
                adAccountCreationRequest.Company = this.company;
                
                if(adAccountCreationRequest.Company === Company.CINC)
                    adAccountCreationRequest.Brokerage = this.brokerage;

                if(adAccountCreationRequest.Company === Company.RealGeeks)
                    adAccountCreationRequest.TimeZone = this.timeZone;

                this.displayInfo = true;
                this.infoMessage = "Creating an ad account...";
                this.createDisabled = true;
                await this.$store.dispatch('creator/createCincConfiguredAdAccount', adAccountCreationRequest)
                .then((response: any) => {
                    let adAccountId = response.data.id;
                    let adAccountName = response.data.accountName;
                    this.infoMessage = "Ad account created. Configuring ad account permissions..."
                    var accountPermissionsRequest = new AdAccountPermissionsRequest();
                    accountPermissionsRequest.AdAccountId = adAccountId;
                    accountPermissionsRequest.Company = this.company;
                    this.$store.dispatch('adAccounts/grantAdAccountPermissions', accountPermissionsRequest)
                        .then((response : any) => {
                            this.setCreatorInfo(adAccountCreationRequest, adAccountId, adAccountName);
                            this.displaySuccess = true;
                        })
                        .catch((error : string) => {
                            this.displayError = true;
                            this.errorMessge = `The ad account was created, but an error occurred while configuring permissions. Please try configuring the ad account in the Permissions Granter. Ad Account ID: ${adAccountId}. ${error}`;
                        })
                        .finally(() => {
                            this.createDisabled = false;
                            this.infoMessage = "";
                            this.displayInfo = false;
                        });
                })
                .catch((error: any) => {
                    this.displayError = true;
                    this.displayInfo = false;
                    this.createDisabled = false;
                    this.errorMessge = `An error occurred, error message: ${error} Please try again or report the issue.`;
                })
            }
            this.formValidationClass = "was-validated";
        }
        else{
            this.form = document.getElementsByClassName('needs-validation')[0];
            await this.createAdAccountRequest(e);
        }
    }

    async setCreatorInfo(adAccountCreationRequest: AdAccountCreationRequest, adAccountId: string, adAccountName: string) {
        let creatorInfo: CreatorInfo = {
            ClientFullName: adAccountCreationRequest.ClientFullName,
            DomainName: adAccountCreationRequest.DomainName,
            States: adAccountCreationRequest.States,
            AdAccountId: adAccountId,
            AdAccountName: adAccountName,
            Company: this.company,
            Campaigns: [],
        };
        await this.$store.dispatch('creator/setCreatorInfo', creatorInfo);
    }
}
</script>../../models/request/AdAccountCreationRequest../../models/request/AdAccountPermissionsRequest