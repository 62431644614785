<template>
    <div class="component-container">
        <h1 class="display-6 mt-4 mb-3">Report Builder</h1>
        <p class="col-sm-6">Use this form to request a new report. The report will be saved to the Reports page folder structure.</p>
        <form class="needs-validation" novalidate>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="company" class="form-label">Company</label>
                    <select v-model="company" @change="checkRequestButtonAvailable()" class="form-select" id="company" required>
                        <option selected disabled value=0>Please Select a Company</option>
                        <option value=1>CINC</option>
                        <option value=2>Real Geeks</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="reportType" class="form-label">Report Type</label>
                    <select v-model="reportType" @change="checkRequestButtonAvailable()" class="form-select" id="reportType" required>
                        <option selected disabled value=-1>Please Select a Report Type</option>
                        <option value=0>Current Month Lead Report</option>
                        <option value=1>Previous Month Lead Report</option>
                        <option value=2>Custom Date Range Lead Report</option>
                        <option value=3>Last Thirty Lead Report</option>
                    </select>
                </div>
            </div>

            <div v-if="reportType == 2" class="row">
                <div class="col-md-3 mb-3">
                    <label for="startDate" class="form-label">Start Date</label>
                    <input @change="(event) => setStartDate(event)" type="date" class="form-control" id="startDate" :value="getStartDateString()" required>
                </div>
                <div class="col-md-3 mb-3">
                    <label for="endDate" class="form-label">End Date</label>
                    <input @change="(event) => setEndDate(event)" type="date" class="form-control" id="startDate" :value="getEndDateString()" required>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-2 mb-3">
                    <button class="btn btn-primary" :disabled=reportButtonDisabled @click="sendReportRequest">Request Report</button>
                </div>
            </div>
            <div v-if="displayAccepted" class="row">
                <div class="col-md-6 mb-3">
                    <div class="alert alert-success" role="alert">
                        Report Request Accepted. Please check the Facebook Automation slack channel for notification of the report completion. Then download from the <router-link to="/facebook/reports/existing">Reports</router-link> page.
                    </div>
                </div>
            </div>
            <div v-if="displayError" class="row">
                <div class="col-md-6 mb-3">
                    <div class="alert alert-danger" role="alert">
                        Error: {{errorMessage}}
                    </div>
                </div>
            </div>
        </form> 
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { ReportCreationRequest } from '../../models/request/ReportCreationRequest';
import { Company } from '../../enums/Company';

@Component({
    name: 'ReportBuilder'
})
export default class ReportBuilder extends Vue {

    reportType: number = -1;
    startDate: Date = new Date();
    endDate: Date = new Date();

    displayAccepted: boolean = false;
    displayError: boolean = false;
    errorMessage: string = '';
    reportButtonDisabled: boolean = true;
    company: Company = Company.None;

    getStartDateString() : string {
        if(!this.startDate) {
            this.startDate = new Date(Date.now());
        }
        return this.startDate.toISOString().split('T')[0];
    }
    setStartDate(event: any) : void {
        this.startDate = new Date(event.target.value);
    }

    getEndDateString() : string {
        if(!this.endDate) {
            this.endDate = new Date(Date.now());
        }
        return this.endDate.toISOString().split('T')[0];
    }
    setEndDate(event: any) : void {
        this.endDate = new Date(event.target.value);
    }

    ////Create method for sending report request
    async sendReportRequest(e: Event) {
        e.preventDefault();
        e.stopPropagation();

        this.reportButtonDisabled = true;
        this.displayAccepted = false;
        this.displayError = false;

        if(this.reportType === -1) {
            return;
        }

        let reportRequest = new ReportCreationRequest();
        reportRequest.Company = this.company;
        reportRequest.Type = this.reportType;
        if(this.reportType == 2) {
            reportRequest.StartDate = this.startDate;
            reportRequest.EndDate = this.endDate;
        }

        await this.$store.dispatch('report/sendReportRequest', reportRequest)
        .then((response) => {
            this.displayAcceptedMessage();
        })
        .catch((error) => {
            this.displayErrorMessage(error.response.data);
        })
        .finally(() => {
            this.reportButtonDisabled = false;
        });
    }
    checkRequestButtonAvailable() {
        if(this.reportType !== -1 && this.company !== Company.None) {
            this.reportButtonDisabled = false;
        }
    }

    private displayAcceptedMessage() {
        this.displayAccepted = true;
    }

    private displayErrorMessage(error: string) {
        this.displayError = true;
        this.errorMessage = error;
    }

}
</script>../../models/request/ReportCreationRequest